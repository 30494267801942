<template>
<div id="GalleryOverview" class="animated fadeIn slower">

<v-container fluid class="darken-1" >

<div class="titleDiv px-5">
  <p class="display-1">Gallery</p>
</div>

<article id="gallerycontainer">
<v-row>
<v-col cols="12" sm="12" md="4" class="mb-5 pb-5" v-for="gallery in GalleryData" :key="gallery.imageUrl" height="100%">
  <v-card class="image-card elevation-5 animated fadeIn slower" height="100%" >

    <v-img class="animated fadeIn slow white--text align-end" height="300px" :src="gallery.imageUrl">
       <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
      <div class="pa-1" style="background-color: rgba(0,0,0,0.4);" v-if="gallery.title != ''">
          <p class="d-flex align-start pt-5 pl-2">{{gallery.title}}</p>
      </div>
    </v-img>

  </v-card>
</v-col>
</v-row>
</article>

</v-container>

</div>
</template>

<script>
export default {

    name: 'galleryoverview',

    metaInfo: {
        title: 'Gallery',
    },

    computed : {
      GalleryData(){
        return this.$store.getters.GetGallery;
      },
    },
}
</script>

<style scoped>
#GalleryOverview{
  padding: 3vw;
  padding-top: 2rem !important;
  color: #eee !important;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 2rem;
}
.image-card{
  border-radius: 10px !important;
}
</style>